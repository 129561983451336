body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background: linear-gradient(135deg, #29B9DB, #003F3F);
    color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.app-container {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 600px;
    text-align: center;
}

h1 {
    color: #003F3F;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

label {
    font-weight: bold;
    color: #003F3F;
    margin-bottom: 5px;
}
.label-date {
    margin-bottom: 15px;
    display: block;
}

.date-picker-input {
    width: 100%;
    max-width: 250px;
    padding: 10px;
    border: 1px solid #003F3F;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
}


.date-picker-input:focus {
    border-color: #FFC466;
    outline: none;
}

button {
    background-color: #29B9DB;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    margin-top: 10px;
}

button:hover {
    background-color: #003F3F;
}

.image-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-container img {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border: 2px solid #FFC466;
    border-radius: 10px;
    margin-top: 10px;
}

.email-input-container {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

.email-input {
    width: 70%;
    padding: 10px;
    border: 1px solid #003F3F;
    border-radius: 5px;
    font-size: 16px;
}

.email-input:focus {
    border-color: #FFC466;
    outline: none;
}

.mint-nft-button {
    background-color: #29B9DB;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.mint-nft-button:hover {
    background-color: #003F3F;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.loading p {
    margin-top: 10px;
    color: #003F3F;
}

.email-input-container {
    margin-top: 20px;
    display: flex;
    align-items: center; /* Zentriert die Elemente vertikal */
}

.email-input {
    width: 100%; /* Füllt den gesamten Container */
    padding: 10px;
    border: 1px solid #003F3F;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 5px; /* Abstand zwischen Input und Icon */
}

.email-input:focus {
    border-color: #FFC466;
    outline: none;
}

.scanner-button {
    padding: 0; /* Kein zusätzliches Padding */
    width: 40px; /* Breite für das Icon */
    height: 40px; /* Höhe für das Icon */
    display: flex; /* Flexbox für zentrierte Positionierung */
    align-items: center; /* Vertikale Zentrierung */
    justify-content: center; /* Horizontale Zentrierung */
}

h2 {
    color: #29B9DB;
}

h3{
    color: #29B9DB;
}

.checkbox-container label {
    font-size: 20px;
    padding: 4px;
    display: inline-grid;
    gap: 5px;
}

.checkbox-container input[type="checkbox"] {
    transform: scale(1.5); /* Checkboxen vergrößern */
    margin-right: 10px; /* Abstand zwischen Checkbox und Label */
    accent-color: #FFC466;
    border: 2px solid transparent; /* Standardmäßiger transparenter Rahmen */
    border-radius: 4px; /* Abgerundete Ecken für den Rahmen */
    outline: none; /* Entfernt den Standard-Outline */
}

/* Rahmen, wenn die Checkbox ausgewählt ist */
.checkbox-container input[type="checkbox"]:checked {
    border: 2px solid #FFC466; /* Rahmenfarbe bei Auswahl */
    background-color: rgba(255, 198, 102, 0.3); /* Optional: Hintergrundfarbe beim Auswählen */
}

#frei {
    border: 2px solid #29B9DB;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
}

#frei:focus {
    border-color: #FFC466;
    outline: none;
}


.reload-button,
.mint-nft-button {
    margin-top: 20px;
}

.error-message {
    background-color: #f8d7da; /* Helle Rotfarbe für den Hintergrund */
    color: #721c24; /* Dunkelrote Farbe für den Text */
    padding: 15px;
    border: 1px solid #f5c6cb; /* Hellrote Grenze */
    border-radius: 5px; /* Abgerundete Ecken */
    margin: 10px 0; /* Abstand nach oben und unten */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reload-button {
    background-color: #FFC466; /* Türkisfarbe für den Button */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px; /* Abstand zwischen Text und Button */
}

.small-font {
    font-size: 8px;
}

.checkbox-grid-container {
    margin-top: 20px; /* Abstand nach oben für den Container */
}

.checkbox-grid-container h2 {
    color: #003F3F; /* Farbe für die Überschrift */
    margin-bottom: 10px; /* Abstand nach unten zur nächsten Zeile */
}

.style-checkboxes {
    display: grid; /* Setzt den Container auf Grid */
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Anzahl der Spalten, je nach Bildschirmgröße */
    gap: 10px; /* Abstand zwischen den Checkboxen */
}

.style-label {
    display: flex; /* Flexbox für zentrierte Positionierung */
    align-items: center; /* Vertikale Zentrierung */
    font-size: 14px; /* Schriftgröße für die Labels */
    margin: 0; /* Keine zusätzliche Margen, da wir gap verwenden */
}
