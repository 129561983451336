body, html, #root {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.App {
    text-align: center;
    margin-top: 50px;
}
